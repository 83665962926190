<template>
	<div class="letter">
		<w-list :loading="loading" :finished="finished" scroll-view=".message-scroll" :is-empty="isEmpty"
			@onLoad="onLoad">
			<div class="letter-item" v-for="(vo, key) in letterList" @click="onChatDetail(key)">
				<div class="letter-header">
					<div class="letter-title">{{ vo.title }}</div>
					<div class="letter-dot dot-red" v-if="vo.have_read == 2"></div>
				</div>
				<div class="letter-attr">
					<div class="letter-attr-left">
						<div class="letter-box">
							<div class="letter-picture"><img src="@/assets/img/icon6.png" alt="" /></div>
							<div class="letter-time">{{ vo.create_time }}</div>
						</div>
						<div class="letter-box">
							<div class="letter-picture"><img src="@/assets/img/icon16.png" alt="" /></div>
							<div class="letter-view">浏览：{{ vo.views }}</div>
						</div>
					</div>
					<div class="letter-attr-right">
						<div class="letter-picture"><img src="@/assets/img/icon213.png" alt="" /></div>
						<div class="letter-type">{{ vo.type == 1 ? "活动通知" : "站内公告" }}</div>
					</div>
				</div>
			</div>
		</w-list>
	</div>
</template>

<script>
import Message from '@/api/message';
import WList from '@/components/List';

export default {
	data() {
		return {
			letterList: [],
			isEmpty: false,
			loading: false,
			finished: false,
			page: 1,
			limit: 10,
		};
	},
	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			Message.myLetter({
				page: this.page,
				limit: this.limit
			}).then(result => {

				this.letterList = this.letterList.concat(result.data.list);

				if (this.letterList.length <= 0) {
					this.isEmpty = true;
				}

				if (this.letterList.length >= result.data.total) {
					this.finished = true;
				}

				this.page++;
				this.loading = false;
			}).catch(error => {
				console.log(error);
			});
		},
		onChatDetail(index) {
			this.letterList[index].have_read = 1;

			this.$store.commit('letter/decUnreadTotal');
			this.$router.push({
				name: 'MessageLetterDetail',
				params: {
					id: this.letterList[index].letter_id
				}
			})
		}
	},
	components: {
		WList
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/style.less');

.letter {
	.letter-item {
		padding: 15px 10px;
		background-color: #fff;
		border-radius: 6px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		.letter-header {
			position: relative;

			.letter-title {
				font-weight: bold;
				font-size: 16px;
				line-height: 22px;
				padding-bottom: 12px;
				.border-bottom();
			}
		}

		.letter-attr {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 12px;

			.letter-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.letter-picture {
				width: 16px;
				min-width: 16px;
				height: 16px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.letter-attr-left {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.letter-time,
			.letter-view,
			.letter-type {
				font-size: 14px;
				line-height: 16px;
				color: #666;
				margin-left: 3px;
			}
			.letter-time{
				margin-right: 15px;
			}

			.letter-view {
				margin-left: 3px;
			}

			.letter-attr-right {
				display: flex;
				align-items: center;
			}
		}
	}
}
</style>
