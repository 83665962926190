<template>
	<div class="group">
		<div class="group-item" v-for="vo in group" @click="$router.push({ name: 'GroupChat', params: { id: vo.id } })">
			<div class="group-item-left">
				<img :src="vo.image" />
				<div class="dot-red" v-show="vo.unread"></div>
			</div>
			<div class="group-item-right">
				<div class="group-item-content">
					<div class="group-item-title">{{ vo.title }}</div>
					<div class="group-item-message" v-if="vo.message">
						<template v-if="vo.message.type == 'text'">
							{{ vo.message.name }}: {{ vo.message.content }}
						</template>
						<template v-if="vo.message.type == 'image'">
							{{ vo.message.name }}: [图片]
						</template>
						<template v-if="vo.message.type == 'voice'">
							{{ vo.message.name }}: [语音]
						</template>
						<template v-if="vo.message.type == 'revoke'">
							{{ vo.message.content }}
						</template>
						<template v-if="vo.message.type == 'quit'">
							{{ vo.message.content }}
						</template>
					</div>
					<div class="group-item-message" v-else>暂无信息</div>
				</div>
				<div class="group-item-last" v-if="vo.message">{{ getTimeEquation(vo.message.create_time) }}</div>
			</div>
		</div>
		<div class="group-empty" v-if="group.length <= 0">
			<van-empty :image="require('@/assets/img/empty.png')" description="这里是空的哦" />
		</div>
	</div>
</template>

<script>
import WList from '@/components/List';
import MomentVendor from '@/vendor/moment';

export default {
	name: 'MessageGroup',
	data() {
		return {};
	},
	computed: {
		group() {
			return this.$store.state.group.group.sort((a, b) => {
				return MomentVendor(b.last_time).format('X') - MomentVendor(a.last_time).format('X');
			});
		},
	},
	methods: {
		getTimeEquation(sendTime) {
			let diff = Math.abs(MomentVendor(sendTime).diff(MomentVendor(), 'minute'));

			if (diff <= 1440 && MomentVendor(sendTime).format('YYYY-MM-DD') == MomentVendor().format('YYYY-MM-DD')) {
				return MomentVendor(sendTime).format('a hh:mm');
			}

			return MomentVendor(sendTime).format('YYYY-MM-DD');
		},
	},
	components: {
		WList,
	},
};
</script>

<style lang="less" scoped>
.group {
	.group-item {
		display: flex;
		align-items: center;
		background-color: #fff;
		padding: 12px 15px 12px 12px;
		border-radius: 6px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		.group-item-left {
			width: 40px;
			min-width: 40px;
			height: 40px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 100%;
			}
		}

		.group-item-right {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1;
			padding-left: 5px;

			.group-item-content {
				padding-right: 5px;
			}

			.group-item-title {
				font-size: 16px;
				line-height: 22px;
				color: #333;
			}

			.group-item-message {
				font-size: 12px;
				line-height: 22px;
				color: #666;
				max-width: 200px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-webkit-text-overflow: ellipsis;
			}

			.group-item-last {
				font-size: 13px;
				line-height: 24px;
				color: #666;
				min-width: 70px;
			}
		}
	}

	.group-empty {
		/deep/.van-empty__image {
			width: 100px;
			height: 100px;
		}
	}
}
</style>
