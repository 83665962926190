<template>
	<div class="message">
		<scroll-view class="message-scroll">
			<div class="banner"><img :src="require('@/assets/img/scene1.png')" /></div>
			<div class="container">
				<div class="tab-container">
					<w-tab :active="active" :data="tabs" @click="onTab"></w-tab>
				</div>

				<message-group v-show="active == 0"></message-group>
				<message-letter v-if="active == 1"></message-letter>
			</div>
		</scroll-view>
		<w-tabbar></w-tabbar>
	</div>
</template>

<script>
import WTab from '@/components/Tab';
import MessageGroup from '@/views/message/components/MessageGroup';
import MessageLetter from '@/views/message/components/MessageLetter';
import ScrollView from '@/components/ScrollView';
import scrollMiXin from '@/mixins/scroll';
import WTabbar from '@/components/Tabbar';

const scroll = scrollMiXin(['.message-scroll']);

export default {
	name: 'Message',
	mixins: [scroll],
	data() {
		return {
			text: [],
			tabFixed: false,
			fixedOffset: 200,
			active: 0,
		};
	},
	computed: {
		tabs() {

			let groupIsShowDot = false;

			for (let vo in this.$store.state.group.group) {
				if (this.$store.state.group.group[vo].unread == true) {
					groupIsShowDot = true;
					break;
				}
			}

			return [
				{
					title: '群聊信息',
					route: { name: 'MessageGroup' },
					scrollTop: 0,
					isShowDot: groupIsShowDot,
				},
				{
					title: '重要通知',
					route: { name: 'MessageLetter' },
					scrollTop: 0,
					isShowDot: this.$store.state.letter.unreadTotal > 0,
				}
			]
		}
	},
	created() {
		this.$store.commit('keep/setKeep', 'Message');
	},
	methods: {
		onTab(index) {
			this.active = index;
		}
	},
	components: {
		WTab,
		WTabbar,
		ScrollView,
		MessageGroup,
		MessageLetter
	}
};
</script>

<style lang="less" scoped>
.message {
	min-height: calc(100vh - 50px);
	height: calc(100vh - 50px);

	.message-scroll {
		position: relative;
	}

	.banner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}
	}

	.container {
		position: absolute;
		top: 185px;
		left: 0;
		background-color: #f2f2f2;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		width: 100%;
		padding: 15px 10px;
		box-sizing: border-box;

		.tab-container {
			height: 30px;
			margin-bottom: 10px;
		}
	}
}
</style>
